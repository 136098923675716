<template>
  <div id="age-input" style="position: relative">
    <v-text-field
      :value="value"
      :menu-props="{ bottom: true, offsetY: true }"
      label="Age"
      clearable
      hide-details
      outlined
      @click:clear="onClear"
      @input="onInputChange"
    >
      <template v-slot:append>
        <v-icon
          icon
          color="black"
          style="height: 100%"
          class="mt-0"
          @click="onMenuActivatorClick"
        >
          {{ menuIcon }}
        </v-icon>
      </template>
    </v-text-field>
    <v-menu
      v-model="isMenuActive"
      attach="#age-input"
      absolute
      :position-x="menuPosition.x"
      :position-y="menuPosition.y"
      :close-on-content-click="false"
      :min-width="menuWidth"
    >
      <v-card>
        <div class="pa-3 ml-1 mr-1" style="width: 315px">
          <div class="d-flex justify-space-between align-center mb-5">
            <span class="txet-subtitle-2 font-weight-bold">Age</span>

            <v-switch
              v-model="isRange"
              hide-details
              label="Range"
              class="mt-0"
              @change="onChangeRangeToggle"
            />
          </div>

          <v-slider
            v-if="!isRange"
            :value="slider"
            :max="rangeLimits.max"
            :min="rangeLimits.min"
            :thumb-size="18"
            :thumb-label="true"
            hide-details
            @change="onRangeChange"
          />
          <v-range-slider
            v-else
            :value="rangeSlider"
            :max="rangeLimits.max"
            :min="rangeLimits.min"
            :thumb-size="18"
            :thumb-label="true"
            hide-details
            @change="onRangeChange"
          />

          <div class="d-flex justify-space-between align-center mb-">
            <span class="txet-subtitle-1 ml-1">{{ rangeLimits.min }}</span>
            <span class="txet-subtitle-1 mr-1">{{ rangeLimits.max }}</span>
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { debounce } from "lodash";

export default {
  name: "RangePicker",

  props: {
    value: {
      type: String,
      default: () => null,
    },
  },

  data() {
    const rangeLimits = {
      min: 0,
      max: 100,
    };

    return {
      rangeLimits,
      isRange: false,
      searchValue: null,
      slider: 1,
      rangeSlider: [rangeLimits.min, rangeLimits.max],
      isMenuActive: false,
      menuPosition: {
        x: 0,
        y: 0,
      },
      menuWidth: 320,
    };
  },

  computed: {
    menuIcon() {
      return this.isMenuActive ? "mdi-menu-up" : "mdi-menu-down";
    },
  },

  watch: {
    value(newValue) {
      if (!newValue) {
        this.slider = this.rangeLimits.min;
        this.rangeSlider = [this.rangeLimits.min, this.rangeLimits.max];
        return;
      }
      const parsedValue = newValue
        .split("-")
        .map((el) => Number(el))
        .filter((num) => !isNaN(num));

      if (parsedValue.length > 1) {
        this.isRange = true;
      } else {
        this.isRange = false;
      }

      if (this.isRange) {
        const startValue = parsedValue[0] || this.rangeLimits.min;
        const endValue = parsedValue[1] || this.rangeLimits.max;
        this.rangeSlider = [startValue, endValue];
        return;
      }
      this.slider = parsedValue[0] || this.rangeLimits.min;
    },
  },

  methods: {
    onChangeRangeToggle(value) {
      if (value) {
        this.$emit("change", [this.slider, this.rangeLimits.max].join("-"));
      } else {
        this.$emit("change", `${this.rangeSlider[0]}`);
      }
    },
    onInputChange: debounce(function (value) {
      this.$emit("change", value);
    }, 500),
    onClear() {
      this.$emit("change", null);
      this.rangeSlider = [this.rangeLimits.min, this.rangeLimits.max];
      this.slider = this.rangeLimits.min;
      this.isMenuActive = false;
    },
    onRangeChange(value) {
      if (this.isRange) {
        this.$emit("change", value.join("-"));
        return;
      }
      this.$emit("change", `${value}`);
    },
    onMenuActivatorClick() {
      const inputPosition = document
        .getElementById("age-input")
        .getBoundingClientRect();
      this.menuPosition.x = inputPosition.width - this.menuWidth;
      this.menuPosition.y = inputPosition.height;
      this.isMenuActive = true;
    },
  },
};
</script>
